import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import logoBrainWhite from "../img/brain-white.svg";

export default class PostList extends React.Component {
  formatSlug(str) {
    let regex = /\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])*/gm;

    if (regex.test(str)) {
      // Remove YYYY-MM-DD from blog slug
      str = str
        .split("/blog/")[1]
        .replace(regex, "")
        .substr(1);
      // Rebuild slug string
      str = str.replace(/^-/, "");
      str = "/blog/" + str;
    }

    return str;
  }

  render() {
    const title =
      "Amos Institute Blog | Evidence based nutrition and health information";
    const description =
      "Explore evidence based nutrition and health information, resources, and support. Easily accessible materials to improve cognitive and overall health.";
    const posts = this.props.data.allMarkdownRemark.edges;

    var newTag = null;
    if (
      this.props.location.pathname === "/blog/" ||
      this.props.location.pathname === "/blog"
    ) {
      newTag = <span className="tag new-tag is-primary is-light">NEW</span>;
    }

    return (
      <Layout>
        <Helmet title={title}>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div className="blog">
          <div
            className="page-header full-width-image-container"
            style={{
              backgroundColor: "#4FB4F8",
            }}
          >
            <h1 className="has-text-white has-text-weight-bold is-size-1">
              <img
                className="header-img inline"
                src={logoBrainWhite}
                alt="Amos Institute Logo"
              />{" "}
              Blog
            </h1>
            <p className="has-text-white has-text-centered subtitle">
              Explore evidence based nutrition and health information,
              resources, and support. Easily accessible materials to improve{" "}
              <br /> cognitive and overall health.
            </p>
          </div>
          <section className="blog-category-menu section">
            <div className="container">
              <div className="columns">
                <Link className="column is-one-fifth" to="/blog/recipes/">
                  <div className="category-title is-size-5" data-tag="Recipes">
                    Recipes
                  </div>
                  <small className="has-text-grey-dark">
                    Bredesen Protocol approved recipes curated by our registered
                    dietitian nutritionists.
                  </small>
                </Link>
                <Link className="column is-one-fifth" to="/blog/nutrition/">
                  <div
                    className="category-title is-size-5"
                    data-tag="Nutrition"
                  >
                    Nutrition
                  </div>
                  <small className="has-text-grey-dark">
                    Stay current with the latest research for optimizing and
                    personalizing your nutrition plan.
                  </small>
                </Link>
                <Link className="column is-one-fifth" to="/blog/lifestyle/">
                  <div
                    className="category-title is-size-5"
                    data-tag="Lifestyle"
                  >
                    Lifestyle
                  </div>
                  <small className="has-text-grey-dark">
                    Exercise, sleep, stress management techniques and more to
                    improve your brain and overall health.
                  </small>
                </Link>
                <Link className="column is-one-fifth" to="/blog/news/">
                  <div className="category-title is-size-5" data-tag="News">
                    News
                  </div>
                  <small className="has-text-grey-dark">
                    Breaking headlines and noteworthy information regarding
                    cognitive health and the Bredesen Protocol.
                  </small>
                </Link>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <div className="content">
                <div className="columns is-multiline">
                  {posts &&
                    posts.map(({ node: post }, i) => (
                      <div className="column is-4" key={post.id}>
                        <Link
                          className="card"
                          to={this.formatSlug(post.fields.slug)}
                        >
                          {i === 0 ? newTag : ""}

                          {post.frontmatter.featuredimage ? (
                            <div className="card-image">
                              <PreviewCompatibleImage
                                imageInfo={{
                                  image: post.frontmatter.featuredimage,
                                  alt: `featured image thumbnail for post ${post.title}`,
                                }}
                              />
                            </div>
                          ) : null}
                          <div className="card-content">
                            <small
                              className="card-category-tag"
                              data-tag={post.frontmatter.tags}
                            >
                              {post.frontmatter.tags}
                            </small>
                            <div className="card-title has-text-weight-semibold is-size-4">
                              {post.frontmatter.title}
                            </div>
                            <div className="card-desc">
                              {post.frontmatter.description}
                            </div>
                            <div className="card-footer">
                              <span className="has-text-grey">
                                {post.frontmatter.date}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {this.props.pageContext.previousPagePath ? (
                    <Link
                      className="button is-outlined is-rounded"
                      to={this.props.pageContext.previousPagePath}
                      style={{
                        marginRight: "20px",
                      }}
                    >
                      Previous
                    </Link>
                  ) : null}

                  {this.props.pageContext.nextPagePath ? (
                    <Link
                      className="button is-outlined is-rounded"
                      to={this.props.pageContext.nextPagePath}
                    >
                      Next
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export const postListQuery = graphql`
  query postQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            tags
            date(formatString: "MMMM DD, YYYY")
            description
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 193, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
